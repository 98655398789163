import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { WithCmpSettingsBlocking } from '../../WithCmpSettingsBlocking';
import { t, conf } from '../../../domain/services/configService';
import { OptanonButton } from '../../responsive/atoms/OptanonButton';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';

const isLatamBR = ['br', 'mx'].includes(conf.siteCountryCode);

const YoutubeVideoParagraphInner = ({ videoId, cmpSettings }) => {
  const style = {
    wrapper: css`
      padding-bottom: 56.25%;
      height: 0;
      max-width: 100%;
      overflow: visible;
      position: relative;
      text-align: center;
      margin-bottom: 30px;
      iframe {
        height: 100%;
        left: 0;
        min-height: 100%;
        position: absolute;
        top: 0;
        vertical-align: middle;
        width: 100%;
      }
    `,
    withBorder: css`
      border: solid 4px #000;
      p {
        padding-top: 10%;
        ${theme.mq.tablet} {
          padding-top: 25%;
        }
        font-weight: 400;
        font-family: Georgia, Times, 'Times New Roman', serif;
        font-size: 1.1875rem;
        line-height: 1.9375rem;
        letter-spacing: normal;
      }
    `
  };

  const ytVideo = (
    <div css={style.wrapper}>
      <iframe
        title={videoId}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );

  // @todo - Instead of using "aspect-ratio-wrapper sixteennine", find a better
  // way to make a div take the full width and height of its parent.
  const fallback = (
    <div css={[style.wrapper, style.withBorder]}>
      <p>{t('You need to consent to YouTube cookies to watch this.')}</p>
      <div>
        <OptanonButton color="#333" isLink />
      </div>
    </div>
  );

  return isLatamBR ||
    (cmpSettings &&
      cmpSettings.service_loaded &&
      cmpSettings.cmpTargeting &&
      cmpSettings.cmpPerformance)
    ? ytVideo
    : fallback;
};

YoutubeVideoParagraphInner.defaultProps = {
  cmpSettings: undefined
};

YoutubeVideoParagraphInner.propTypes = {
  videoId: PropTypes.string.isRequired,
  cmpSettings: PropTypes.objectOf(PropTypes.any)
};

export const YoutubeVideoParagraph = ParagraphWrapper(
  WithCmpSettingsBlocking(YoutubeVideoParagraphInner)
);
